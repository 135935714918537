<template>
  <div class="table-responsive w-100 mb-3">
    <a      
      class="m-2 download-btn"
      @click="getCoverstoriesOffsiteFile()"
    >
      <i class="bi bi-file-earmark-excel"> </i>
      {{ $t("DOWNLOAD_COVER_STORIES") }}
    </a>
    <table class="table" id="table" style="height: 90%;">
      <thead class="table-light">
        <tr>
          <th
            scope="col"
            v-for="col in columns"
            :key="col.title"
            class="sticky-top"
            :class="col.title === 'Scenario' ? 'text-center' : ''"
          >
            {{ $t(col.replace(/\ /g, "_").toUpperCase()) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(story, idx) in stories" :key="idx">
          <td class="text-center" width="3rem">{{ idx + 1 }}</td>
          <td>
            <label>
              <textarea
                class="form-control border-0"
                v-model="story.englishText"
                :disabled="configurationReadonly"
                @change="saveStoryTexts(story)"
              ></textarea>
            </label>
          </td>
          <td>
            <label>
              <textarea
                class="form-control border-0"
                v-model="story.localText"
                :disabled="configurationReadonly"
                @change="saveStoryTexts(story)"
              ></textarea>
            </label>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { useStore } from "vuex";
export default {
  name: "CoverStories",
  data() {
    return {
      columns: ["Scenario", "English", "Local language"],
    };
  },
  async setup() {
    const store = useStore();
    await store.dispatch("stories/getStories");
  },
  computed: {
    stories() {
      return this.$store.getters["stories/getStories"];
    },
    configurationReadonly() {
      return this.$store.getters["configurations/currentConfigurationReadonly"];
    },
  },
  methods: {
    async getCoverstoriesOffsiteFile() {
      await this.$store.dispatch("downloads/downloadFile", "coverstories_offsite");
    },
    async saveStoryTexts(story) {
      await this.$store.dispatch("stories/saveStoryTexts", story);
    },
  },
};
</script>

<style lang="scss" scoped>
.download-btn {
  display: block; 
  cursor: pointer; 
  width: fit-content;
  color: black;
  text-decoration: none;
}
.download-btn:hover {
  text-decoration: underline;
}
</style>
